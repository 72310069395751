import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
// import Img from 'gatsby-image';
import Img from 'gatsby-image';
import { useLax } from 'utils/hooks/lax';
import {
    Topic,
    TopicH1,
    TopicHeading,
    TopicParagraph,
    SmallCitation,
    SmallCitationQuote,
    Cards,
    Card,
    CardBody,
    CardBodyImage,
    CardGreenArrow,
    CardBodyLogo,
    ParallaxBody,
    ParallaxImage,
} from 'components';
import {
    Projects,
    ProjectsGirlImage,
} from 'components/projects-page/projects-page';
import LoioLogoSVG from '../img/loio/loio-logo.svg';
import UnicheckLogoSVG from '../img/unicheck/unicheck-logo.svg';

export default function ProjectsPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    useLax();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Phase One Karma — Projects.</title>
                <meta name="description"
                      content="Projects is a high-performance plagiarism checker for educational, professional, and personal use."
                />
            </Helmet>

            <Projects>
                <Topic>
                    <TopicH1>Our projects</TopicH1>
                    <TopicParagraph>
                        P1K's AI-driven solutions grow on the ever-evolving technologies of Machine Learning,
                        natural language processing, and natural language understanding.
                    </TopicParagraph>
                </Topic>

                <Cards>
                    <Card
                        to="/unicheck"
                        data-aos="fade-right"
                        data-aos-offset="200"
                        data-aos-delay={60}
                    >
                        <CardBody>
                            <CardBodyLogo>
                                <img src={UnicheckLogoSVG} alt="Unicheck" />
                            </CardBodyLogo>
                            <h3>Tackling academic dishonesty with Unicheck</h3>
                            <p>AI-driven anti-plagiarism checker with more than 1 million users worldwide.</p>
                            <CardBodyImage>
                                <Img fluid={data.unicheckProjects.childImageSharp.fluid} />
                            </CardBodyImage>

                            <CardGreenArrow />
                        </CardBody>
                    </Card>
                    <Card
                        to="/loio"
                        color="gray"
                        data-aos="fade-left"
                        data-aos-offset="200"
                        data-aos-delay={60}
                    >
                        <CardBody>
                            <CardBodyLogo>
                                <img src={LoioLogoSVG} alt="Loio" />
                            </CardBodyLogo>
                            <h3>Loio — a shortcut to perfect legal documents</h3>
                            <p>Advanced styling, comparison, and AI-analysis for legal contracts in one add-in.</p>
                            <CardBodyImage>
                                <Img fluid={data.loioProjects.childImageSharp.fluid} />
                            </CardBodyImage>

                            <CardGreenArrow />
                        </CardBody>
                    </Card>
                </Cards>

                <Topic>
                    <TopicHeading>Aspiring for the best</TopicHeading>
                    <TopicParagraph>
                        We believe the success of P1K's projects depends on a motivated team and the R&D approach
                        to solving emerging digital issues. Our Research and Development department does its best
                        to explore the products' demand and potential technological capacities. Then, together,
                        we wrap them up with an innovative approach.
                    </TopicParagraph>
                </Topic>

                <ParallaxBody>
                    <ParallaxImage
                        rootImage
                        data-lax
                        data-lax-anchor="self"
                        data-lax-translate-y="vh 0, -1000 100"
                    >
                        <Img fluid={data.clouds1.childImageSharp.fluid} loading="lazy" />
                    </ParallaxImage>

                    <ParallaxImage
                        data-lax
                        data-lax-anchor="self"
                        data-lax-translate-y="vh 100, -1000 -100"
                    >
                        <Img fluid={data.birds1.childImageSharp.fluid} loading="lazy" />
                    </ParallaxImage>
                    <ParallaxImage
                        data-lax
                        data-lax-anchor="self"
                        data-lax-translate-y="vh 0, -1000 100"
                        data-lax-translate-x="vh 0, -1000 -100"
                    >
                        <Img fluid={data.birds2.childImageSharp.fluid}
                             loading="lazy"
                        />
                    </ParallaxImage>
                </ParallaxBody>

                <Topic>
                    <TopicHeading>Our AI-powered products grow on the evolving technologies</TopicHeading>
                    <TopicParagraph>
                        In 2014, we created <a href="https://unicheck.com" target="_blank">Unicheck</a>, a unique
                        AI-powered anti-plagiarism checker, to endorse academic integrity worldwide. 2017 was
                        also a great year — P1K introduced and integrated EMMA, an authorship verification
                        assistant built around NLP.
                    </TopicParagraph>
                    <TopicParagraph>
                        Two years later, we took full speed on creating even more meaningful products, starting
                        with Loio. <a href="https://loio.com/" target="_blank">Loio</a> is an add-in to help
                        transform the daily tiresome work for lawyers all over the world. Yes, global again!
                        The add-in's features include document styling, numbering, tracking contract versions, and
                        up-to-date AI-analysis. Loio is driven by NLP and NLU, carefully devised to free the
                        lawyers’ time from editing and proofreading contracts.
                    </TopicParagraph>
                </Topic>


                <ProjectsGirlImage
                    data-lax
                    // data-lax-opacity="0 1, 200 1, 400 0"
                    data-lax-translate-y="vh 0, 400 -100"
                >
                    <Img fluid={data.girl.childImageSharp.fluid} fadeIn={false}
                         style={{ position: 'absolute' }} />
                    <Img fluid={data.girlSkate.childImageSharp.fluid} fadeIn={false}
                         style={{ position: 'absolute' }} />
                </ProjectsGirlImage>

                <SmallCitation>
                    <SmallCitationQuote>
                        P1K does not stop here —
                        stay tuned for our new projects!
                    </SmallCitationQuote>
                </SmallCitation>
            </Projects>
        </>
    );
}

export const imgQuery = graphql`
    query {
        girl: file(relativePath: { eq: "projects/girl.png" }) {
            childImageSharp {
                fluid(maxWidth: 520, srcSetBreakpoints: [520], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        girlSkate: file(relativePath: { eq: "projects/girl-skate.png" }) {
            childImageSharp {
                fluid(maxWidth: 520, srcSetBreakpoints: [520], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }


        unicheckProjects: file(relativePath: { eq: "unicheck/unicheck-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 720, srcSetBreakpoints: [720], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        loioProjects: file(relativePath: { eq: "loio/loio-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 720, srcSetBreakpoints: [720], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }


        clouds1: file(relativePath: { eq: "projects/clouds-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1366, srcSetBreakpoints: [1366], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        birds1: file(relativePath: { eq: "projects/birds-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1366, srcSetBreakpoints: [1366], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        birds2: file(relativePath: { eq: "projects/birds-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1366, srcSetBreakpoints: [1366], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
