import React, { HTMLProps, PropsWithChildren } from 'react';
import { Grid } from 'components/grid/grid';
import styles from './projects-page.module.scss';

export function Projects(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.projects} {...props} />;
}

export function ProjectsGirlImage(props: PropsWithChildren<HTMLProps<HTMLDivElement>>): JSX.Element {
    return (
        <Grid className={styles.projects__girlGrid}>
            <div className={styles.projects__girl} {...props} />
        </Grid>
    );
}


